@charset "UTF-8";
/* Styles imported from the mother Lindholmen theme, which we need for WYSIWYG stuff. */
.accent {
  color: #00a1a1;
  font-weight: bold;
}

.main-content .cta {
  background-color: #666;
  color: #fff;
  display: block;
  font-size: 1.222222222em; /* ≈ 22px */
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 15px 30px 9px;
}

.cta-arrow,
.cta-arrow-short,
.main-content a.cta-arrow {
  background-color: #666;
  color: #fff;
  display: block;
  font-size: 1.222222222em; /* ≈ 22px */
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 15px;
  text-align: right;
  position: relative;
  padding-right: 2.3em;
}

.cta-arrow:before,
.cta-arrow-short:before {
  font-family: "Lindholmen icons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-size: 1.2em;
  text-transform: none;
  line-height: 1;
  content: "\e60d";
  float: right;
  position: relative;
  top: 0.055555556em;
  margin-left: 0.277777778em;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -0.45em;
}

@media only screen and (min-width: 568px) {
  .cta-arrow-short {
    width: 40%;
  }
}
.cta:hover,
.cta-arrow:hover,
.cta-arrow-short:hover {
  background-color: #545454;
  color: #fff;
  text-decoration: none;
}

.right-arrow {
  display: inline-block;
  position: relative;
  padding-right: 15px;
}

.right-arrow:before {
  content: "\e60d";
  font-family: "Lindholmen icons";
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1.3;
  margin-left: 0.625em;
  position: absolute;
  right: -20px;
  speak: none;
  text-transform: none;
  top: 50%;
  transform: translateY(-50%);
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.content__preamble .right-arrow:before {
  right: -35px;
}

@media only screen and (min-width: 450px) {
  .main-content .cta {
    display: inline-block;
  }
}
/**
  * @name Left
  * @description Style for the left class
  * @markup
  *   <div class="left">markup</div>
  */
.left {
  float: left;
}

@media only screen and (max-width: 767px) {
  .left {
    float: none;
  }
}
/**
  * @name Right
  * @description Style for the right class
  * @markup
  *   <div class="right">markup</div>
  */
.right {
  float: right;
}

.video-left iframe,
.video-right iframe {
  max-width: 100%;
}

@media only screen and (max-width: 767px) {
  .right {
    float: none;
  }
  .video-left,
  .video-right,
  .image-left,
  .image-right {
    width: 100% !important;
    display: block;
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 768px) {
  .video-left,
  .video-right,
  .image-left,
  .image-right {
    max-width: 50% !important;
    display: block;
    margin-bottom: 10px;
  }
  .video-left,
  .image-left {
    float: left;
    margin-right: 10px;
  }
  .video-right,
  .image-right {
    float: right;
    margin-left: 10px;
  }
}
table {
  display: inline-block;
  background: #fff;
  width: 100% !important;
  border: 1px solid #fff;
  border-collapse: collapse;
  margin-bottom: 1em;
}

tbody {
  display: inline;
  border-top: none;
}

th, td {
  border: none;
  padding: 0;
}

.program-table td {
  vertical-align: top;
  padding-bottom: 1em;
}

.program-table td:first-child {
  padding-right: 15px;
  width: 20%;
}

@media only screen and (min-width: 450px) {
  .program-table td:first-child {
    padding-right: 8px;
    white-space: nowrap;
  }
}
/**
 * Responsive table
 */
.responsive-table {
  margin-bottom: 0;
}

.responsive-table tr {
  display: inline;
}

.responsive-table td {
  display: block;
  width: 100%;
  float: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden !important;
  border: none;
  margin-bottom: 20px;
  padding-right: 20px;
}

.responsive-table td.last {
  padding-right: 0;
}

.content__body .responsive-table td {
  padding-right: 2.22%;
}

.content__body .responsive-table td.last {
  padding-left: 2.22%;
  padding-right: 0;
}

@media only screen and (max-width: 767px) {
  .responsive-table td {
    height: auto !important;
    padding-right: 0 !important;
  }
  .content__body .responsive-table td.last {
    padding-left: 0;
  }
}
@media only screen and (min-width: 768px) {
  .responsive-table td {
    width: 50%;
    /*margin-bottom: 30px;*/
  }
}
@media only screen and (min-width: 1024px) {
  .responsive-table td {
    width: 33.333333333%;
    /*margin-bottom: 40px;*/
  }
  .content__body .responsive-table td {
    width: 50%;
  }
}
/**
 * Gridset IE 8 fix
 */
@media screen and (min-width: 768px) {
  .t-hide {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  .d-hide {
    display: none;
  }
}
/**
 * Newsletter
 */
.block-mailchimp-signup {
  margin-bottom: 1em;
}

.block-mailchimp-signup h2 {
  font-size: 1.125em; /* ≈ 18px */
  color: #333;
}

.sidebar .block-mailchimp-signup .block__title {
  font-size: 24px;
  margin-bottom: 5px;
}

.block-mailchimp-signup .mailchimp-signup-subscribe-form-description {
  font-size: 0.9375em; /* ≈ 15px */
  margin-bottom: 0.5em;
}

.block-mailchimp-signup .form-item {
  position: relative;
  margin: 0;
}

.block-mailchimp-signup .form-text {
  border: 1px solid #ebebeb;
  border-radius: 0;
  width: 100%;
  font-size: 0.875em;
  height: 38px;
  padding: 0 14px;
  vertical-align: middle;
  -webkit-appearance: none;
}

.block-mailchimp-signup .form-type-textfield {
  float: left;
  width: 70%;
}

.block-mailchimp-signup label {
  position: absolute;
  color: #bbb;
  font-size: 1em; /* ≈ 16px */
  font-weight: normal;
  top: 0.55em;
  left: 1em;
}

.block-mailchimp-signup input[type=submit] {
  color: #fff;
  cursor: pointer;
  width: 30%;
  font-size: 16px;
  background: #666;
  border: 0;
  border-radius: 0 2px 2px 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 38px;
  margin-right: 0;
  padding: 0;
  vertical-align: middle;
  -webkit-appearance: none;
}

.block-mailchimp-signup input[type=submit]:hover {
  background: #545454;
}

.block-mailchimp-signup .form-actions {
  margin: 0;
}

.sidebar .block-mailchimp-signup .form-actions {
  margin: 0;
}

.post {
  position: relative;
  background: none;
  padding: 0 0 20px;
  margin: 0 0 20px;
  border-bottom: 1px solid #ebebeb;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.post.last {
  border-bottom: none;
}

@media only screen and (min-width: 768px) {
  .post {
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media only screen and (min-width: 768px) {
  .post--featured {
    border-bottom: none;
  }
}
.post__inner {
  position: relative;
}

.post__meta {
  color: #999;
  font-size: 0.888888889em; /* ≈ 16px */
  line-height: 1.222222222em; /* ≈ 22px */
  padding-bottom: 8px;
}

.post__meta--bold {
  font-size: 1em; /* ≈ 18px */
}

.post__image {
  margin-bottom: 10px;
}

@media only screen and (min-width: 420px) {
  .post__image {
    margin-left: 10px;
    float: right;
    width: 34.4459279039%;
  }
}
.post__image img {
  width: 100%;
  display: block;
}

.meta {
  color: #999;
  font-size: 16px;
  line-height: 1.625; /* ≈ 24px */
  margin-bottom: 1em;
}
.meta--event .icon-before--pin {
  padding-left: 3px;
}

.preamble {
  color: #808b8a;
  font-size: 1.2em; /* ≈ 22px */
  line-height: 1.214285714;
  margin-bottom: 0.833333333em; /* ≈ 20px */
  padding: 0 10px;
}

.preamble p {
  margin-bottom: 0.357142857em; /* ≈ 10px */
}

.preamble--page {
  margin-bottom: 1.428571429em; /* ≈ 40px */
}

@media only screen and (min-width: 450px) {
  .preamble {
    font-size: 1.333333333em; /* ≈ 24px */
    padding: 0;
  }
}
@media only screen and (min-width: 910px) {
  .node-full {
    margin-bottom: 150px;
  }
}
.node-page .content__body {
  margin-top: 20px;
}

/* Styles for faq in wysiwyg */
.cke_editable .faq-question {
  border: 1px dashed #aaa;
  margin-bottom: 1px;
  font-size: larger;
  padding: 2px 4px;
  margin-bottom: 1rem;
}
.cke_editable .faq-answer {
  border: 1px dashed #aaa;
  margin-left: 6px;
  padding: 2px 4px;
  margin-bottom: 1rem;
}